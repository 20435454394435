import React from 'react'
import { Layout, SEO, MailingList } from '../components'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { FaDirections, FaEnvelope, FaPhone } from 'react-icons/fa'
import { graphql } from 'gatsby'

// Query for the Blog Home content in Prismic
export const query = graphql`
  query ContactUsQuery {
    prismicRestaurantInfo {
      data {
        phone_number {
          text
        }
        google_maps_url {
          target
          url
        }
        address_one {
          text
        }
        city {
          text
        }
        province {
          text
        }
        zip_code {
          text
        }
        monday_hours
        tuesday_hours
        wednesday_hours
        thursday_hours
        friday_hours
        saturday_hours
        sunday_hours
      }
    }

    prismicMailingList {
      data {
        image {
          url
          alt
        }
        post_url {
          url
        }
        text {
          text
        }
        title {
          text
        }
        email_input_name
        audience_id
        user_id
      }
    }
  }
`

const parseRestaurantInfo = (data) => {
  const phoneNumber = data.phone_number && data.phone_number.text
  const googleMapsUrl = data.google_maps_url && data.google_maps_url.url
  const addressOne = data.address_one && data.address_one.text
  const city = data.city && data.city.text
  const province = data.province && data.province.text
  const zipCode = data.zipCode && data.zipCode.text
  const businessHours = [
    { Monday: data.monday_hours },
    { Tuesday: data.tuesday_hours },
    { Wednesday: data.wednesday_hours },
    { Thursday: data.thursday_hours },
    { Friday: data.friday_hours },
    { Saturday: data.saturday_hours },
    { Sunday: data.sunday_hours },
  ]
  return {
    googleMapsUrl,
    phoneNumber,
    businessHours,
    addressOne,
    city,
    province,
    zipCode,
  }
}

const ContactUs = ({ data }) => {
  const restaurantInfo = data.prismicRestaurantInfo.data
  const mailingList = data.prismicMailingList && data.prismicMailingList.data

  const {
    googleMapsUrl,
    phoneNumber,
    businessHours,
    addressOne,
    city,
    province,
    zipCode,
  } = parseRestaurantInfo(restaurantInfo)

  return (
    <Layout pageInfo={{ pageName: 'contact-us' }}>
      <SEO title="Contact Us | Huis Shave Ice" />
      <Container className="mt-4 mb-5 mt-md-5 mb-md-5">
        <Row>
          <Col
            sm={{ span: 10, offset: 1 }}
            md={{ span: 8, offset: 2 }}
            lg={{ span: 6, offset: 3 }}
            className="text-center mb-3"
          >
            <h1> Contact Us </h1>
          </Col>
          <Col
            sm={{ span: 2, offset: 5 }}
            md={{ span: 2, offset: 5 }}
            lg={{ span: 2, offset: 5 }}
            className="text-center"
          ></Col>
        </Row>
        <Row>
          <Col
            sm={{ span: 10, offset: 1 }}
            md={{ span: 4, offset: 1 }}
            lg={{ span: 4, offset: 1 }}
          >
            <Row className="justify-content-center">
              <h2>Hours</h2>
            </Row>
            {businessHours.map((dayObj) => {
              const day = Object.keys(dayObj)[0]
              const time = dayObj[day]
              return (
                <Row className="justify-content-center" key={day}>
                  <h3>
                    {day}: {time}
                  </h3>
                </Row>
              )
            })}
          </Col>
          <Col
            sm={{ span: 10, offset: 1 }}
            md={{ span: 5, offset: 2 }}
            lg={{ span: 4, offset: 2 }}
            className="text-center"
          >
            {phoneNumber && (
              <>
                <h2>Phone</h2>
                <Button
                  className="huis-contact__button huis-contact__button--mode-dark huis-contact__button--phone"
                  href={'tel:+1' + phoneNumber}
                  variant="outline-primary"
                  rel="noreferrer"
                  onClick={() => {
                    typeof window !== 'undefined' &&
                      window.gtag('event', 'click', {
                        event_category: 'outbound',
                        event_label: facebookUrl,
                        value: 'contact-us phone number',
                      })
                  }}
                >
                  {phoneNumber}
                  <FaPhone className="ml-2" />
                </Button>
              </>
            )}
            {addressOne && (
              <>
                <h2 className={phoneNumber ? 'mt-4 mt-md-5' : ''}>Location</h2>
                <h3>
                  {addressOne}
                  <br />
                  {city}, {province},
                  <br />
                  {zipCode}
                </h3>
                <Button
                  variant="outline-primary"
                  className="huis-contact__button huis-contact__button--mode-dark"
                  href={googleMapsUrl}
                  target="_blank"
                  onClick={() => {
                    typeof window !== 'undefined' &&
                      window.gtag('event', 'click', {
                        event_category: 'outbound',
                        event_label: googleMapsUrl,
                        value: 'contact-us directions',
                      })
                  }}
                  rel="noreferrer"
                >
                  Get Directions{' '}
                  <FaDirections className="huis-header__social-icon icon-google-maps" />
                </Button>
              </>
            )}
          </Col>
        </Row>
      </Container>

      {mailingList && Object.keys(mailingList).length && (
        <MailingList
          imgSrc={mailingList.image.url}
          altText={mailingList.image.alt}
          title={mailingList.title.text}
          text={mailingList.text.text}
          postUrl={mailingList.post_url.url}
          userId={mailingList.user_id}
          audienceId={mailingList.audience_id}
          emailInputName={mailingList.email_input_name}
        />
      )}
    </Layout>
  )
}

export default ContactUs
